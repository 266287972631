import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Truevined - Hang Time",
  "featuredImage": "images/Hang+Time.jpg",
  "coverAlt": "Truevined - Hang Time",
  "description": "Branding, Graphic Design, Video, social media marketing for Truevined",
  "date": "2018-10-03",
  "dateModified": "2018-10-03",
  "category": "portfolio",
  "pinned": false,
  "featured": false,
  "tags": ["Graphic Design", "Music"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Artwork for Truevined’s newest track + Music visual`}</p>
    <h2 {...{
      "id": "screenshots",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#screenshots",
        "aria-label": "screenshots permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Screenshots`}</h2>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1440px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/ac62c226b723114f1d773b1bd72a5582/04bec/Hang%2BTime.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAECBAMF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAHVCsbS80mfiDQJ/8QAGhABAAMBAQEAAAAAAAAAAAAAAQACAyEEEf/aAAgBAQABBQIqwFj9haa7VybenpZlns//xAAYEQADAQEAAAAAAAAAAAAAAAAAERIBEP/aAAgBAwEBPwGETg+f/8QAGREAAQUAAAAAAAAAAAAAAAAAAAEQERIT/9oACAECAQE/AdJLK/8A/8QAGhAAAgIDAAAAAAAAAAAAAAAAADEBEBEgcf/aAAgBAQAGPwIVYIh8Fp//xAAbEAACAgMBAAAAAAAAAAAAAAAAAREhQVFhEP/aAAgBAQABPyHBVE1OVWLITe3S5EkJazXWbYx28//aAAwDAQACAAMAAAAQm9f9/8QAGREBAAIDAAAAAAAAAAAAAAAAAQAREDFB/9oACAEDAQE/EF7wTsVSXj//xAAZEQADAAMAAAAAAAAAAAAAAAAAAREQIWH/2gAIAQIBAT8QltHMix//xAAaEAEBAQEBAQEAAAAAAAAAAAABEQAhMVFB/9oACAEBAAE/EEQdRxcF4BUDphSj59dGkQTOuAVohqiyfvTABu/cyq9xv//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Truevined - Hang Time",
              "title": "Truevined - Hang Time",
              "src": "/static/ac62c226b723114f1d773b1bd72a5582/33266/Hang%2BTime.jpg",
              "srcSet": ["/static/ac62c226b723114f1d773b1bd72a5582/543cd/Hang%2BTime.jpg 360w", "/static/ac62c226b723114f1d773b1bd72a5582/20801/Hang%2BTime.jpg 720w", "/static/ac62c226b723114f1d773b1bd72a5582/33266/Hang%2BTime.jpg 1440w", "/static/ac62c226b723114f1d773b1bd72a5582/04bec/Hang%2BTime.jpg 1600w"],
              "sizes": "(max-width: 1440px) 100vw, 1440px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Truevined - Hang Time`}</figcaption>{`
  `}</figure></p>
    <h2 {...{
      "id": "music-visual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#music-visual",
        "aria-label": "music visual permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Music Visual`}</h2>
    <p>{`“`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen frameborder="0" height="560" src="https://www.youtube.com/embed/DLCNb0tk8DU?ecver=1" width="100%"></iframe>`}</code></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      